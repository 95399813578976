<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
    <el-drawer v-model="formDrawerVisible2" size="30%" title="详情">
      <div v-if="formDrawerVisible2" style="padding-left: 16px; padding-right: 16px;">
        <formSee :columns="addColumnsDet" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import listToolbar from '@/components/system/listToolbar.vue'
import listTable from '@/components/system/listTable.vue'
import { schoolDelAdmin, schoolListAdmin, schoolSubmitAdmin, schoolUpdateStatus,schoolList } from '@/apis/common'
import Form from '@/components/system/form.vue'
import formSee from '@/components/system/formSee.vue'

export default {
  name: 'school',
  components: { formSee, Form, listTable, listToolbar },
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 15,
      total: 0,
      toolbar: [
        {type: 'input', label: '学校名称', model: 'name', value: ''},
        {type: 'input', label: '联系人', model: 'link_name', value: ''},
        {type: 'input', label: '联系电话', model: 'link_phone', value: ''},
        {type: 'select', label: '显示状态', model: 'status', value: '', options: [
            {label: '全部', value: ''},
            {label: '正常', value: '1'},
            {label: '冻结', value: '0'},
        ]},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      loading: false,
      list: [],
      columns: [
        {label: '学校ID', prop: 'id', width: 100, type: 'text'},
        {label: '学校分类', prop: 'type_id', width: 100, type: 'select',options: []},
        {label: '学校名称', prop: 'name', width: 200, type: 'text'},
        {label: '联系人', prop: 'link_name', width: 100, type: 'text'},
        {label: '联系电话', prop: 'link_phone', width: 100, type: 'text'},
        {label: '教师数量', prop: 'teacher_num', width: 80, type: 'text'},
        {label: '学生数量', prop: 'student_num', width: 80, type: 'text'},
        {label: '状态', prop: 'status', width: 80, type: 'switch', change: (e,v) => this.changeStatus(e,v)},
        {label: '创建时间', prop: 'created_at', width: 150, type: 'text'},
        {label: '操作', width: 260, type: 'but', arr: [
            {type: 'default', icon: 'el-icon-document', label: '查看', onClick: (row) => this.seeDetails(row)},
            {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
            {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)}
        ]}
      ],
      typeForm: 'add',
      formData: {
        name: '',
        link_name: '',
        link_phone: '',
        status: '',
      },
      formDrawerVisible: false,
      formDrawerVisible2: false,
      addFormData: {},
      addColumns: [
        {label: '学校名称', prop: 'name', type: 'input',value: ''},
        {label: '学校分类', prop: 'type_id', type: 'select', value: '',options: []},
        {label: '联系人', prop: 'link_name', type: 'input',value: ''},
        {label: '联系电话', prop: 'link_phone', type: 'input',value: ''},
        {label: '省份', prop: 'province_id', type: 'province',value: ''},
        {label: '城市', prop: 'city_id', type: 'city',value: ''},
        {label: '详细地址', prop: 'address', type: 'input',value: ''},
        {label: '备注', prop: 'note', type: 'textarea',value: ''},
        {label: '账号信息', prop: 'account_json', type: 'arr',value: []}
      ],
      addColumnsDet: [
        {label: '学校名称', prop: 'name', type: 'input',value: ''},
        {label: '学校分类', prop: 'type_id', type: 'select',value: '', options: []},
        {label: '联系人', prop: 'link_name', type: 'input',value: ''},
        {label: '联系电话', prop: 'link_phone', type: 'input',value: ''},
        {label: '省份', prop: 'province_id', type: 'province',value: ''},
        {label: '城市', prop: 'city_id', type: 'city',value: ''},
        {label: '详细地址', prop: 'address', type: 'input',value: ''},
        {label: '创建时间', prop: 'created_at', type: 'input',value: ''},
        {label: '备注', prop: 'note', type: 'textarea',value: ''},
        {label: '账号信息', prop: 'account_json', type: 'arr',value: []},
      ]
    }
  },
  mounted() {
    this.getSchoolListAdmin()
    this.getSchoolList()
  },
  methods: {
    // 分页
    pageChange(e) {
      this.page = e
      this.getSchoolListAdmin()
    },
    // 查看
    seeDetails(row) {
      this.addColumnsDet.forEach(item => {
        item.value = row[item.prop]
        if(item.prop === 'account_json') {
          item.value = row.account
        }
      })
      this.formDrawerVisible2 = true
    },
    // 删除
    del(row) {
      this.addFormData = row
      this.$confirm('确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        schoolDelAdmin({
          admin_id: this.info.id,
          agency_id: this.info.agency_school_id,
          school_id: row.id
        }).then(res => {
          this.$message.success('删除成功')
          this.getSchoolListAdmin()
        })
      }).catch(() => {})
    },
    submit(e,n) {
      let form = {
        admin_id: this.info.id,
        agency_id: this.info.agency_school_id,
        school_id: n === 'edit' ? this.addFormData.id : '',
        ...e
      }
      schoolSubmitAdmin(form).then(res => {
        this.formDrawerVisible = false
        this.$message.success('操作成功')
        this.getSchoolListAdmin()
      })
    },
    changeStatus(e,v) {
      schoolUpdateStatus({
        agency_id: this.info.agency_school_id,
        school_id: v.id,
        admin_id: this.info.id,
        status: e ? 1 : 0
      }).then(res => {})
    },
    // 修改
    modify(row) {
      this.addFormData = row
      this.typeForm = 'edit'
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
        if(item.prop === 'account_json') {
          item.value = row.account
        }
      })
      this.formDrawerVisible = true
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    toolTap(data) {
      this.formData = data
      this.page = 1
      this.getSchoolListAdmin()
    },
    getSchoolList() {
      schoolList({
        admin_id: this.info.id,
        agency_id: this.info.agency_school_id
      }).then(res => {
        let data = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
        this.addColumns[1].options = data
        this.addColumnsDet[1].options = data
        this.columns[1].options = data
      })
    },
    getSchoolListAdmin() {
      this.loading = true
      schoolListAdmin({
        page: this.page,
        pagesize: this.pageSize,
        admin_id: this.info.id,
        agency_id: this.info.agency_school_id,
        ...this.formData
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list.map(x => {
          x.status = x.status === 1
          return x
        })
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
